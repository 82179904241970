export const getOverviewData = () => {
  return [
    {
      // sectionImage: 'Overview/credit-one-bank-club-logo.png',
      sectionTitle: "The Field",
      paragraph:
        "offers a versatile, pillar-free space capable of hosting events of all sizes, from keynote presentations and closing receptions to gala dinners and immersive brand activations. Uniquely positioned in Las Vegas, this one-of-a-kind venue allows limitless creativity—from thrilling football experiences, where guests can live out their childhood dreams of kicking a field goal, to intimate dinners for 35 on the iconic 50-yard line. With its unmatched setting and flexibility, this event space promises an unforgettable experience that will leave a lasting impression on all attendees.",
      area: "95,000",
      reception: "3,500+",
      banquet: "2,500+",
    },
  ];
};

export const getCarouselImages = () => {
  return [
    // 'Field/Photos/field-bg.jpg',
    "Field/Photos/field-01.jpg",
    "Field/Photos/field-02.jpg",
    "Field/Photos/field-03.jpg",
    "Field/Photos/field-04.jpg",
    "Field/Photos/field-05.jpg",
    // 'Field/Photos/field-06.jpg',
  ];
};

export const getLayoutImages = () => {
  return [
    "Field/Layout/layout-field-1.jpg",
    "Field/Layout/layout-field-2.jpg",
    "Field/Layout/layout-field-3.jpg",
    "Field/Layout/layout-field-4.jpg",
  ];
};

export const getLayoutImagesMobile = () => {
  return [
    "Field/Layout/layout-field-mobile-1.png",
    "Field/Layout/layout-field-mobile-2.png",
    // 'Field/Layout/layout-field-3.jpg',
    // 'Field/Layout/layout-field-4.jpg'
  ];
};
export const getCarouselInspirationImages = () => {
  return [
    "Field/event-photos/field-event-new1.jpg",
    "Field/event-photos/field-event-new2.jpg",
    "Field/event-photos/field-event-new3.jpg",
    "Field/event-photos/field-event-new4.jpg",
    "Field/event-photos/field-event-new5.jpg",
    "Field/event-photos/field-event-new6.jpg",
    // "Field/event-photos/field-event-new7.jpg",
    // "Field/event-photos/field-event-1.jpg",
    "Field/event-photos/field-event-2.jpg",
    "Field/event-photos/field-event-3.jpg",
    "Field/event-photos/field-event-4.jpg",
    "Field/event-photos/field-event-5.jpg",
    "Field/event-photos/field-event-6.jpg",
    // "Field/event-photos/field-event-7.jpg",
    "Field/event-photos/field-event-8.jpg",
    // "Field/event-photos/field-event-9.jpg",
    // "Field/event-photos/field-event-10.jpg",
    "Field/event-photos/field-event-11.jpg",
    "Field/event-photos/field-event-12.jpg",
    "Field/event-photos/field-event-13.jpg",
    // "Field/event-photos/field-event-14.jpg",
    // "Field/event-photos/field-event-15.jpg",
    // "Field/event-photos/field-event-16.jpg",
    // "Field/event-photos/field-event-17.jpg",
    // "Field/event-photos/field-event-18.jpg",
    "Field/event-photos/field-event-19.jpg",
    // "Field/event-photos/field-event-20.jpg",
    "Field/event-photos/field-event-21.jpg",
    // "Field/event-photos/field-event-22.jpg",
    "Field/event-photos/field-event-23.jpg",
    // "Field/event-photos/field-event-24.jpg",
    // "Field/event-photos/field-event-25.jpg",
    "Field/event-photos/field-event-26.jpg",
    "Field/event-photos/field-event-27.jpg",
    // "Field/event-photos/field-event-28.jpg",
    // "Field/event-photos/field-event-29.jpg",
  ];
};
